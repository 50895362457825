































import { Component, Watch } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import SupportAndDownloadApi from "@/api/SupportAndDownload/SupportDownloadApi"
import _ from "lodash"
import { deserialize } from "typescript-json-serializer"
import qs from "qs"
import SupportAndDownload from "../../types/SupportAndDownload/SupportAndDownload"
import BreakpointMixin from "../../types/BreakpointMixin"

@Component({
  name: "SupportAndDownloadIndex"
})
export default class SupportAndDownloadList extends mixins(BreakpointMixin) {
  private items: SupportAndDownload[] = []

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  mounted() {
    this.fetchData()
  }

  private fetchData() {
    SupportAndDownloadApi.listSupportDownload().then(({ data }) => {
      this.items = _.map(data, (d) => deserialize<SupportAndDownload>(d, SupportAndDownload))
    })
  }

  // private viewSupportAndDownload(group: SupportAndDownload) {
  //   // eslint-disable-next-line @typescript-eslint/camelcase
  //   localStorage.setItem("productFilter", qs.stringify({ product_group: [group.id] }))
  //   const lang = this.$i18n.locale
  //   this.$router.push({
  //     name: "ProductCategoryList",
  //     params: { id: `${group.id}` },
  //     query: { lang }
  //   })
  // }

  private generateRoute(link: string) {
    const query = link.split("?")[1].split("&")
    let queryParams: {} = {}

    query.forEach((params: string) => {
      Object.assign(queryParams, { [params.split("=")[0]]: params.split("=")[1] })
    })

    return {
      name: "SupportAndDownload",
      //@ts-ignore
      query: { view: queryParams.view, id: queryParams.id, lang: this.$i18n.locale, top: true }
    }
  }

  private get imageHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 392
    }
    if (bp.mdAndUp) {
      return 275
    }
    return 275
  }

  private get imageWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 396
    }
    if (bp.mdAndUp) {
      return 264
    }
    return 275
  }
}
